import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { calculateItemsToAddRemove } from "../../features/product";


import TableTemplate from "../Templates/TableTemplate";

const ProductTemplate = ({
  showTable,
  fournisseurFilteredProducts,
  columns,
  selectedProductComponents,
  handleCheckboxChange,
  handleAddSelectedToCart,
  totalResultComposants,
  handleDeleteSelected
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(calculateItemsToAddRemove());
  }, [dispatch]); 

  /* const product = useSelector((state) => state.product); 
  console.log(product);
  const itemsToAdd = useSelector(state => state.product.itemsToAdd);
  console.log(itemsToAdd);
  const itemsToRemove = useSelector(state => state.product.itemsToRemove);
  console.log(itemsToRemove); */
  const product = useSelector((state) => state.product); 
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [itemsToAdd, setItemsToAdd] = useState(0);
  const [itemsToRemove, setItemsToRemove] = useState(0);

  useEffect(() => {
    const countItemsToAdd = (selectedProductComponents || []).filter(
      (itemId) => !cartItems.some((cartItem) => cartItem.id === itemId)
    ).length;
    const countItemsToRemove = (selectedProductComponents || []).filter(
      (itemId) => cartItems.some((cartItem) => cartItem.id === itemId)
    ).length;
    setItemsToAdd(countItemsToAdd);
    setItemsToRemove(countItemsToRemove);
  }, [cartItems, selectedProductComponents]);
  
  return (
    <>
      {/* Action buttons for desktop */}
      <section className="section py-0 is-hidden-mobile">
        <div className="buttons are-small-mobile are-large-desktop is-pulled-left">
          <button
            className="button is-primary is-responsive"
            onClick={handleAddSelectedToCart}
            disabled={itemsToAdd === 0}
          >
            <span className="is-hidden-mobile" title="Ajouter au Panier">Ajouter au Panier ({itemsToAdd})</span>
          </button>
          <button
            className="button is-danger is-light is-inverted is-responsive"
            onClick={handleDeleteSelected}
            disabled={itemsToRemove === 0}
          >
            <span className="is-hidden-mobile" title="Supprimer du panier">Supprimer du panier ({itemsToRemove})</span>
          </button>
        </div>
      </section>
      <section className="section py-0">
        <div className="buttons are-small-mobile are-large-desktop is-pulled-right">
          {((selectedProductComponents.length > 0 || itemsToAdd > 0 || itemsToRemove > 0) && selectedProductComponents.length !== fournisseurFilteredProducts.length) && (
            <button
              className="button is-info is-inverted is-responsive is-pulled-right"
              onClick={handleAddSelectedToCart}
            >
              <span className="" title="Annuler la sélection">Annuler la sélection ({selectedProductComponents.length})</span>
            </button>
          )}
          {selectedProductComponents.length !== fournisseurFilteredProducts.length ? (
            <button
              className="button is-info is-inverted is-responsive"
              onClick={() => handleCheckboxChange("all")}
            >
              Sélectionner tous les résultats ({totalResultComposants})
            </button>
          ) : (
            <button
              className="button is-info is-light is-responsive"
              onClick={() => handleAddSelectedToCart()}
            >
              Désélectionner tous les résultats ({totalResultComposants})
            </button>
          )}
        </div>
      </section>
      <section className="section">
        {showTable && (
          <TableTemplate
            data={fournisseurFilteredProducts}
            columns={columns}
          />
        )}
      </section>
    </>
  );
};

export default ProductTemplate;

ProductTemplate.propTypes = {
  showTable: PropTypes.bool.isRequired,
  fournisseurFilteredProducts: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  totalResultComposants: PropTypes.number.isRequired,
  selectedProductComponents: PropTypes.array.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleAddSelectedToCart: PropTypes.func.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
};

