import "bulma/css/bulma.css";
import "./MainLayout.scss";

import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import imageLogo from "./../assets/image002.png";
import imageLogoBlanc from "./../assets/AUSYRIS.png";

import Navigation from "./../components/Navigation";
import Footer from "./../components/Footer";

function MainLayout({children}) {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    const $navbar = document.querySelector(".navbar");
    const $navbarBrand = document.querySelector(".navbar-brand");
    const $navbarMenu = document.querySelector(".navbar-menu");

    if (scrollTop >= 200) {
      $navbar.classList.add("sticky");
      $navbarMenu.classList.add("sticky");
      $navbarBrand.classList.add("sticky");
      document.getElementById("ausyris-logo").src = imageLogoBlanc;
    } else {
      $navbar.classList.remove("sticky");
      $navbarMenu.classList.remove("sticky");
      $navbarBrand.classList.remove("sticky");
      document.getElementById("ausyris-logo").src = imageLogo;
    }
  });

  return (
    <div className="App"
      id="AUSYRIS">
      <section className="hero is-primary is-halfheight is-from-top banner-home">
        <Navigation />
        <div className="hero-body"></div>
      </section>
      <div className="body-content">
        {children}
      </div>
      <Footer />
    </div>
  );
}

export default MainLayout;

MainLayout.propTypes = {
  children: PropTypes.object
};
