import React from "react";

import Ausyris from "./Ausyris/index";
import OurExpertise from "./OurExpertise";
import DiscussWithUs from "./DiscussWithUs";
import MechanicalAndHydraulic from "./MechanicalAndHydraulic";
import MeasurementAndAnalysis from "./MeasurementAndAnalysis";
import ElectronicsAndElectrotechnics from "./ElectronicsAndElectrotechnics";
import IndustrialAutomationAndIT from "./IndustrialAutomationAndIT";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

export default function Home() {

const url =
  "https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7205873098611417090";
const width = 325;
const height = 570;
  return (
    <>
      <Ausyris />
      <OurExpertise />
      <DiscussWithUs />

      <MechanicalAndHydraulic />
      <IndustrialAutomationAndIT />
      <ElectronicsAndElectrotechnics />
      <MeasurementAndAnalysis />

      <AboutUs />
      <ContactUs />
    </>
  );
}
