import React from "react";
import "./style.scss";

import electronics_and_electrotechnics from "!file-loader!./../../../assets/electronics_and_electrotechnics.svg";
import measurement_and_analysis from "!file-loader!./../../../assets/measurement_and_analysis.svg";
import industrial_automation_and_it from "!file-loader!./../../../assets/industrial_automation_and_it.svg";
import mechanical_and_hydraulic from "!file-loader!./../../../assets/mechanical_and_hydraulic.svg";

import Card from "./../../Cards/Card";

const img_mechanical_and_hydraulic = { src: mechanical_and_hydraulic, alt: "mechanical_and_hydraulic" };
const img_electronics_and_electrotechnics = { src: electronics_and_electrotechnics, alt: "electronics_and_electrotechnics" };
const img_measurement_and_analysis = { src: measurement_and_analysis, alt: "measurement_and_analysis" };
const img_industrial_automation_and_it = { src: industrial_automation_and_it, alt: "industrial_automation_and_it" };

export default function OurExpertise() {
  return (
    <div className="container is-fullhd">
      <section
        className="section is-medium auryris-padding is-align-items-center"
        id="notre-expertise"
      >
        <div className="content is-align-items-center">
          <h2 title="Notre expertise" className="title has-text-link">
            Notre expertise
          </h2>
          <div className="content ausyris-container">
            <p className="subtitle is-size-6-mobile is-size-6-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd is-size-5-fullhd hast-text-dark has-text-centered">
              AUSYRIS c&apos;est l&apos;union d&apos;experts en conception,
              réalisation et exploitation de bancs de tests et de moyens
              d&apos;essais avec des compétences plurielles et complémentaires.
            </p>
          </div>
        </div>
        <div className="block mt-6">
          <div className="columns is-mobile is-multiline">
            <div className="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <Card
                title="mécanique"
                subtltle="& hydraulique"
                link="#mecanique-et-hydraulique"
                linkTitle="En savoir plus"
                image={img_mechanical_and_hydraulic}
              />
              <div className="py-3 is-hidden-touch"></div>
            </div>
            <div className="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <div className="py-3 is-hidden-touch"></div>
              <Card
                title="automatisme &"
                subtltle="informatique"
                link="#automatisme-et-informatique"
                linkTitle="En savoir plus"
                image={img_industrial_automation_and_it}
              />
            </div>
            <div className="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <Card
                title="électronique &"
                subtltle="électrotechnique"
                link="#electronique-et-electrotechnique"
                linkTitle="En savoir plus"
                image={img_electronics_and_electrotechnics}
              />
              <div className="py-3 is-hidden-touch"></div>
            </div>
            <div className="column is-half-mobile is-half-tablet is-one-quarter-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
              <div className="py-3 is-hidden-touch"></div>
              <Card
                title="mesures"
                subtltle="& analyses"
                link="#mesures-et-analyses"
                linkTitle="En savoir plus"
                image={img_measurement_and_analysis}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
