import React from "react";
import { Routes, Route } from "react-router-dom";

import MainLayout from "./../layouts/MainLayout";
import Home from "./Home/Home";

class Pages extends React.Component {
  render() {
    return (
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="*" element={<Home />} exact />
        </Routes>
      </MainLayout>
    );
  }
}
export default Pages;
