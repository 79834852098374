import React from "react";
import PropTypes from "prop-types";

export default function TagsTemplate({ tags }) {
  if (!tags || Object.keys(tags).length === 0) {
    return null;
  }

  return (
    <div className="tags">
      {Object.entries(tags).map(([label, tagData], index) => (
        <span
          key={index}
          className={tagData.className}
          onClick={() => tagData.onTagClick && tagData.onTagClick(tagData.title)}
        >
          {tagData.title}
        </span>
      ))}
    </div>
  );
}

TagsTemplate.propTypes = {
  tags: PropTypes.objectOf(
    PropTypes.shape({
      title: PropTypes.string,
      className: PropTypes.string,
      onTagClick: PropTypes.func,
    })
  ),
};

TagsTemplate.defaultProps = {
  tags: {},
};
