import React from "react";
import { NavLink } from "react-router-dom";
import { LinkedInEmbed } from "react-social-media-embed";

export default function ShopFooter() {
  return (
    <>

      <footer className="footer has-background-dark">
        <div className="container is-fullhd">
          <div className="columns">
            <div className="column is-full is-full-mobile is-full-tablet">
              <div className="content">
                <h6 className="title is-size-6 has-text-primary has-text-left">Coordonées</h6>
                <p className="has-text-left">
                  <a href="https://maps.app.goo.gl/SnyxMKMivwGLj4ib6"
                    className="has-text-light"
                    target="_blank"
                    rel="noreferrer"
                  >57 rue Ernest Renan
                    <br />92000 Nanterre</a></p>
                <p className="has-text-left">
                  <a href="mailto:info@ausyris.com" className=" has-text-primary">Contactez nous</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <footer className="footer has-background-dark">
        <div className="container is-fullhd">
          <div className="columns">
            <div className="column is-3 is-full-mobile is-full-tablet">
              <div className="content">
                <h6 className="title is-size-6 has-text-primary has-text-left">Coordonées</h6>
                <p className="has-text-left">
                  <a href="https://maps.app.goo.gl/SnyxMKMivwGLj4ib6"
                    className="has-text-light"
                    target="_blank"
                    rel="noreferrer"
                  >57 rue Ernest Renan
                    <br />92000 Nanterre</a></p>
                <p className="has-text-left">
                  <a href="mailto:info@ausyris.com" className=" has-text-primary">Contactez nous</a>
                </p>
              </div>
            </div>
            <div className="column is-9 is-full-mobile is-full-tablet">
              <div className="content">
                <h6 className="title is-size-6 has-text-primary has-text-left">
                  Dernières actualités
                </h6>
                <div className="columns is-multiline">
                  <div className="column is-full-mobile is-full-tablet is-half has-background-dark">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <LinkedInEmbed
                        url="https://www.linkedin.com/embed/feed/update/urn:li:share:7166091491906408449"
                        postUrl="https://www.linkedin.com/embed/feed/update/urn:li:share:7166091491906408449"
                        width={325}
                        height={570}
                      />
                    </div>
                  </div>
                  <div className="column is-full-mobile is-full-tablet is-half has-background-dark">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <LinkedInEmbed
                        url="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7087698644803440640"
                        postUrl="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7087698644803440640"
                        width={325}
                        height={570}
                      />
                    </div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      <div className="content has-text-centered py-4 has-background-black is-uppercase has-text-white has-text-weight-semibold">
        <p>
          Mentions légales
          © 2024 ¬ Tous droits réservés ¬
          <strong className="is-uppercase jaune-citron-color">&nbsp;
            <NavLink className="is-uppercase jaune-citron-color" to="/">
              AUSYRIS
            </NavLink>
          </strong>&nbsp;
          ¬&nbsp;<span className="is-capitalized is-italic">Powered by</span>
          <a
            href="https://oasiisbox.fr"
            target="_blank"
            rel="noreferrer"
            className="has-text-light"
          >&nbsp;
            OASIISBOX
            </a>
        </p>
      </div>
    </>
  );
}
