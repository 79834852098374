// src/components/ProductsList/Product.jsx
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getProductByProductId } from "../../features/product";
import ItemActions from "./ItemActions";
import TagsTemplate from "../Templates/TagsTemplate";
import FooterShop from "./../Navigation/FooterShop";
import "./product.scss";

import {
  addOneToCart,
  removeFromCartItem,
  deleteFromCart,
  plusOne,
  removeOne,
} from "../../features/cart";
import "./product.scss";

import TableTemplate from "./../Templates/TableTemplate";
import ProductFooter from "./../Templates/ProductFooter";
import ProductHeader from "./../Templates/ProductHeader";
import ProductTemplate from "./../Templates/ProductTemplate";

export default function Product() {
  const { id } = useParams();
  // console.log(id);

  const [productId, setProductIdhowTable] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductComponents, setselectedProductComponents] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fournisseurSearchTerm, setFournisseurSearchTerm] = useState(""); 

  const product = useSelector((state) => state.product);

  const cartItems = useSelector((state) => state.cart.cartItems);
  const isItemInCart = (itemId) => {
    return cartItems.some((cartItem) => cartItem.id === itemId);
  };

  const dispatch = useDispatch();

  if (!product.productComponents) {
    dispatch(getProductByProductId(productId));
  }

  const originalProductComponents = product.productComponents || [];

  const productComponents = originalProductComponents.map((item) => ({
    id: item.component.id,
    name: item.component.name,
    label: item.component.label,
    description: item.component.description,
    designation_repere: item.component.landmark,
    fabricant: item.component.maker,
    reference_fabricant: item.component.maker_reference,
    fournisseur: item.component.supplier,
    reference_fournisseur: item.component.supplier_reference,
    quantite: item.component.quantity,
    picked: item.component.picked,
    category: item.component.category,
    quantityMin: item.quantity_min,
    quantityMax: 1000,
  }));

  const [showedProducts, setShowedProducts] = useState(productComponents);

  function handleShowTable() {
    setShowTable(true);
    setShowGrid(false);
  }

  function handleShowGrid() {
    setShowTable(false);
    setShowGrid(true);
  }

  const filteredProducts = (productComponents || []).filter((item) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      item.description?.toLowerCase().includes(searchTermLower) ||
      item.designation_repere?.toLowerCase().includes(searchTermLower) ||
      item.fabricant?.toLowerCase().includes(searchTermLower) ||
      item.reference_fabricant?.toLowerCase().includes(searchTermLower) ||
      item.fournisseur?.toLowerCase().includes(searchTermLower) ||
      item.reference_fournisseur?.toLowerCase().includes(searchTermLower)
    );
  });

  // Filter by category
  const categoryFilteredProducts =
    selectedCategories.length > 0
      ? filteredProducts.filter((item) =>
        selectedCategories.includes(item.category)
      )
      : filteredProducts;

  // Filter by fournisseurs (multiple selection)
  const designationFilteredProducts =
    selectedDesignations.length > 0
      ? categoryFilteredProducts.filter((item) =>
        selectedDesignations.includes(item.designation_repere)
      )
      : categoryFilteredProducts;

  // Filter by fournisseurs (multiple selection)
  const fournisseurFilteredProducts =
    selectedFournisseurs.length > 0
      ? designationFilteredProducts.filter((item) =>
        selectedFournisseurs.includes(item.fournisseur)
      )
      : designationFilteredProducts;

  const handleCheckboxChange = (id) => {
    let newselectedProductComponents = [...selectedProductComponents];

    if (id === "") {
      newselectedProductComponents = [];
    } else if (id === "all") {
      if (newselectedProductComponents.length === fournisseurFilteredProducts.length) {
        newselectedProductComponents = [];
      } else {
        newselectedProductComponents = fournisseurFilteredProducts.map(
          (item) => item.id
        );
      }
    } else {
      const index = newselectedProductComponents.indexOf(id);
      if (index === -1) {
        newselectedProductComponents.push(id);
      } else {
        newselectedProductComponents.splice(index, 1);
      }
    }

    setselectedProductComponents(newselectedProductComponents);
  };

  const handleDeleteSelected = () => {
    selectedProductComponents.map((item) => dispatch(deleteFromCart(item)));
    setselectedProductComponents((prevSelected) =>
      prevSelected.filter(
        (id) => !cartItems.some((cartItem) => cartItem.id === id)
      )
    ); // Update selectedProductComponents
  };

  const handleAddSelectedToCart = () => {
    selectedProductComponents.map((item) => dispatch(addOneToCart(item)));
    setselectedProductComponents([]);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    const newSelectedCategories = [...selectedCategories];
    const index = newSelectedCategories.indexOf(category);
    if (index === -1) {
      newSelectedCategories.push(category);
    } else {
      newSelectedCategories.splice(index, 1);
    }

    setSelectedCategories(newSelectedCategories);
  };

  const handleDesignationChange = (event) => {
    setSelectedDesignations(event.target.value);
  };

  const handleFournisseurChange = (event) => {
    const fournisseur = event.target.value;
    const newSelectedFournisseurs = [...selectedFournisseurs];

    // Toggle the fournisseur in the array
    const index = newSelectedFournisseurs.indexOf(fournisseur);
    if (index === -1) {
      newSelectedFournisseurs.push(fournisseur);
    } else {
      newSelectedFournisseurs.splice(index, 1);
    }

    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const handleDeleteFournisseur = (fournisseurToRemove) => {
    const newSelectedFournisseurs = selectedFournisseurs.filter(
      (fournisseur) => fournisseur !== fournisseurToRemove
    );
    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const handleDeleteDesignation = (designationToRemove) => {
    const newSelectedDesignations = selectedDesignations.filter(
      (designation) => designation !== designationToRemove
    );
    setSelectedDesignations(newSelectedDesignations);
  };

  // Get unique designations from product
  const uniqueDesignations = [
    ...new Set(
      (productComponents || []).map((item) => item.designation_repere)
    ),
  ];

  // Get unique fournisseurs from product
  const uniqueFournisseurs = [
    ...new Set((productComponents || []).map((item) => item.fournisseur)),
  ];

  // Filter uniqueFournisseurs to remove selectedFournisseurs
  const availableFournisseurs = uniqueFournisseurs.filter(
    (fournisseur) => !selectedFournisseurs.includes(fournisseur)
  );

  // Filter uniqueDesignations to remove selectedDesignations
  const availableDesignations = uniqueDesignations.filter(
    (designation) => !selectedDesignations.includes(designation)
  );

  const handleFournisseurClick = (fournisseur) => {
    if (selectedFournisseurs.includes(fournisseur)) {
      setSelectedFournisseurs(
        selectedFournisseurs.filter((f) => f !== fournisseur)
      );
    } else {
      setSelectedFournisseurs([...selectedFournisseurs, fournisseur]);
    }
  };

  const handleDesignationClick = (designation) => {
    if (selectedDesignations.includes(designation)) {
      setSelectedDesignations(
        selectedDesignations.filter((d) => d !== designation)
      );
    } else {
      setSelectedDesignations([...selectedDesignations, designation]);
    }
  };

  const uniqueCategories = [
    ...new Set((productComponents || []).map((item) => item.category)),
  ];

  const columns = [
    {
      header: "",
      key: "checkbox",
      render: (item) => (
        <input
          type="checkbox"
          checked={selectedProductComponents.includes(item.id)}
          onChange={() => handleCheckboxChange(item.id)}
        />
      ),
    },
    {
      header: "Designation / Repere",
      key: "designation_repere",
      render: (item) =>
        item.designation_repere === "NULL"
          ? "Aucun"
          : item.designation_repere,
    },
    { header: "Description", key: "description" },
    { header: "Fabricant", key: "fabricant" },
    { header: "Réf.Fabricant", key: "reference_fabricant" },
    { header: "Fournisseur", key: "fournisseur" },
    { header: "Réf.Fournisseur", key: "reference_fournisseur" },
    { header: "Quantité installée", key: "quantite" },
    {
      header: "Panier",
      key: "actions",
      render: (item) => <ItemActions item={item} />,
    },
  ];

  const totalSelectedComponentsQuantity = selectedProductComponents.reduce(
    (total, productId) => {
      const item = fournisseurFilteredProducts.find(
        (item) => item.id === productId
      );
      return total + (item ? item.quantite : 0);
    },
    0
  );

  const totalSelectedComponents = selectedProductComponents.reduce(
    (total, productId) => {
      const item = fournisseurFilteredProducts.find(
        (item) => item.id === productId
      );
      return total + 1;
    },
    0
  );

  const totalQuantity = productComponents.reduce(
    (total, item) => total + item.quantite,
    0
  );
  const totalResultComposants = fournisseurFilteredProducts.length;
  const totalProductComponents = productComponents.length;
  const filteredUniqueFournisseurs = uniqueFournisseurs.filter((fournisseur) => {
    // Check if fournisseur is not null
    if (fournisseur) {
      return fournisseur.toLowerCase().includes(fournisseurSearchTerm.toLowerCase());
    }
    return false; // If fournisseur is null, don't include it in the filtered results
  });

  return (
    <section className="section">
      <h1 className="title">{product.productName}</h1>
      <h2 className="subtitle">
        {product.businessReference} | {product.clientName} |{" "}
        {product.yearOfCreation}
      </h2>

      <hr />
      <div className="columns is-multiline">
        <ProductHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          totalResultComposants={totalResultComposants}
          totalProductComponents={totalProductComponents}
          selectedProductComponents={selectedProductComponents}
          handleDeleteSelected={handleDeleteSelected}
          handleAddSelectedToCart={handleAddSelectedToCart}
          handleCheckboxChange={handleCheckboxChange}
          totalSelectedComponents={totalSelectedComponents}
          totalSelectedComponentsQuantity={totalSelectedComponentsQuantity}
        />
        <div className="column is-full">
          <div className="tabs">
            <ul>
              <li className="is-active"><a>{totalProductComponents} Composant{totalProductComponents > 1 ? "s" : ""}</a></li>
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="tags">
            <span className="tag is-white is-light is-large">
              Composants : {totalProductComponents}
            </span>
            <span className="tag is-white is-light is-large">
              Pièces installées : {totalQuantity}
            </span>
          </div>


          <div className="columns is-multiline">
            <div className="column">
              <nav className="panel">
                <p className="panel-heading">Fournisseur</p>
                <div className="panel-block">
                  <p className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      placeholder="Search"
                      value={fournisseurSearchTerm}
                      onChange={(e) => setFournisseurSearchTerm(e.target.value)}
                    />
                    <span className="icon is-left">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
                {filteredUniqueFournisseurs.length > 0 && (
                  <div>
                    {filteredUniqueFournisseurs.map((fournisseur) => (
                      <a className="panel-block" key={fournisseur}>
                        <span className="panel-icon">
                          <i className="fas fa-book" aria-hidden="true"></i>
                        </span>
                        {fournisseur}
                      </a>
                    ))}
                  </div>
                )}

                {filteredUniqueFournisseurs.length > 0 && ( // Use filteredUniqueFournisseurs here
                  <div>
                    {filteredUniqueFournisseurs.map((fournisseur) => (
                      <a className="panel-block" key={fournisseur}>
                        <span className="panel-icon">
                          <i className="fas fa-book" aria-hidden="true"></i>
                        </span>
                        {fournisseur}
                      </a>
                    ))}
                  </div>
                )}
                <div className="panel-block">
                  <button className="button is-link is-outlined is-fullwidth">
                    Reset all filters
                  </button>
                </div>
              </nav>
            </div>

            <div className="column">
              <nav className="panel">
                <p className="panel-heading">Fournisseur</p>
                <div className="panel-block">
                  <p className="control has-icons-left">
                    <input className="input" type="text" placeholder="Search" />
                  </p>
                </div>
                {uniqueFournisseurs.length > 0 && (
                  <div>



                    <div className="select is-multiple is-fullwidth">
                      <select
                        multiple
                        value={selectedFournisseurs}
                        onChange={handleFournisseurChange}
                        size="6"
                      >
                        {uniqueFournisseurs.map((fournisseur) => (
                          <option
                            key={`filtered_${fournisseur}`}
                            value={fournisseur}
                          >
                            {fournisseur}
                          </option>
                        ))}
                      </select>
                    </div>

                  </div>
                )}
                <div className="panel-block">
                  <button className="button is-link is-outlined is-fullwidth">
                    Reset all filters
                  </button>
                </div>
              </nav>
            </div>
          </div>

          {selectedFournisseurs.length > 0 && (
            <div className="block">
              <h3 className="subtitle is-pulled-left pr-3">
                Filtres appliqués :
              </h3>
              <div className="field is-grouped is-grouped-multiline">
                {selectedFournisseurs.map((fournisseur) => (
                  <div className="control" key={`selected_${fournisseur}`}>
                    <div className="tags has-addons">
                      <a
                        className="tag is-primary is-clickable"
                        onClick={() => handleDeleteFournisseur(fournisseur)}
                      >
                        {fournisseur}
                      </a>
                      <a
                        className="tag is-delete is-clickable"
                        onClick={() => handleDeleteFournisseur(fournisseur)}
                      ></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <ProductTemplate
        showTable={showTable}
        fournisseurFilteredProducts={fournisseurFilteredProducts}
        columns={columns}
        selectedProductComponents={selectedProductComponents}
        handleCheckboxChange={handleCheckboxChange}
      />

      <div className="columns is-multiline">
        <div className="column is-full">
          <h3 className="subtitle is-pulled-left pr-3">Rechercher :</h3>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                placeholder="Rechercher par référence ou nom de pièce"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="column is-full">
          <h4 className="subtitle is-pulled-left is-size-4 pr-3">
            Filtrer par nom de fournisseur :
          </h4>
          <div className="tags">
            {availableFournisseurs.map((fournisseur) => (
              <span
                key={`unique_${fournisseur}`}
                className="tag is-clickable "
                onClick={() => handleFournisseurClick(fournisseur)}
              >
                {fournisseur}
              </span>
            ))}
          </div>
        </div>
        {selectedFournisseurs.length > 0 && (
          <div className="column is-full">
            <h4 className="subtitle is-pulled-left is-size-4 pr-3">
              Filtres appliqués :
            </h4>
            <div className="field is-grouped is-grouped-multiline">
              {selectedFournisseurs.map((fournisseur) => (
                <div className="control" key={`selected_${fournisseur}`}>
                  <div className="tags has-addons">
                    <a
                      className="tag is-primary is-clickable"
                      onClick={() => handleDeleteFournisseur(fournisseur)}
                    >
                      {fournisseur}
                    </a>
                    <a
                      className="tag is-delete is-clickable"
                      onClick={() => handleDeleteFournisseur(fournisseur)}
                    ></a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}


        {selectedProductComponents.length > 0 && (
          <div className="block">
            <div className="card has-background-white">
              <header className="card-header has-background-white">
                <p className="card-header-title">Actions sur la sélection</p>
              </header>
              <div className="card-content has-background-white">
                <ProductFooter
                  totalProductComponents={totalProductComponents}
                  totalQuantity={totalQuantity}
                  totalResultComposants={totalResultComposants}
                  totalSelectedComponents={totalSelectedComponents}
                  totalSelectedComponentsQuantity={totalSelectedComponentsQuantity}
                />
              </div>
              <div className="card-content has-background-white">
                <div
                  className="tags has-addons is-clickable"
                  onClick={() => handleCheckboxChange("")}
                >
                  <span className="tag is-primary is-large">
                    Supprimer toutes les sélections
                  </span>
                  <a className="tag is-large is-delete"></a>
                </div>
              </div>
              <footer className="card-footer has-background-white">
                <a href="#" className="card-footer-item is-success is-light" onClick={handleAddSelectedToCart}>Ajouter au panier</a>
                <a href="#" className="card-footer-item is-warning is-light" onClick={handleDeleteSelected}>Supprimer du panier</a>
                {/* <a href="#" className="card-footer-item is-light" onClick={() => handleCheckboxChange("")}>Annuler la sélection</a> */}
              </footer>
            </div>
          </div>
        )
        }
      </div>


      <div className="hero-foot">
        <ProductFooter
          totalProductComponents={totalProductComponents}
          totalQuantity={totalQuantity}
          totalResultComposants={totalResultComposants}
          totalSelectedComponents={totalSelectedComponents}
          totalSelectedComponentsQuantity={totalSelectedComponentsQuantity}
        />
      </div>

      <section className="section has-background-warning-light">
        <div className="columns is-multiline">
          <div className="column is-full">
            <h3 className="subtitle is-pulled-left pr-3">Rechercher dans les résultats :</h3>
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Rechercher par référence ou nom de pièce"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="column is-full">
            <h4 className="subtitle is-pulled-left is-size-4 pr-3">
              Filtrer par nom de fournisseur :
            </h4>
            <div className="tags">
              {availableFournisseurs.map((fournisseur) => (
                <span
                  key={`unique_${fournisseur}`}
                  className="tag is-clickable "
                  onClick={() => handleFournisseurClick(fournisseur)}
                >
                  {fournisseur}
                </span>
              ))}
            </div>
          </div>

          {selectedFournisseurs.length > 0 && (
            <div className="column is-full">
              <h4 className="subtitle is-pulled-left is-size-4 pr-3">
                Filtres appliqués :
              </h4>
              <div className="field is-grouped is-grouped-multiline">
                {selectedFournisseurs.map((fournisseur) => (
                  <div className="control" key={`selected_${fournisseur}`}>
                    <div className="tags has-addons">
                      <a
                        className="tag is-primary is-clickable"
                        onClick={() => handleDeleteFournisseur(fournisseur)}
                      >
                        {fournisseur}
                      </a>
                      <a
                        className="tag is-delete is-clickable"
                        onClick={() => handleDeleteFournisseur(fournisseur)}
                      ></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="column is-full">
            <ProductFooter
              totalProductComponents={totalProductComponents}
              totalQuantity={totalQuantity}
              totalResultComposants={totalResultComposants}
              totalSelectedComponents={totalSelectedComponents}
              totalSelectedComponentsQuantity={totalSelectedComponentsQuantity}
            />
          </div>
        </div>
      </section>
      <FooterShop />
    </section>
  );
}
