import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import TagsTemplate from "../Templates/TagsTemplate";
import { addOneToCart, removeFromCartItem, plusOne, removeOne } from "../../features/cart";
/* import { selectedProductComponents } from "../../features/product"; */

const ProductHeader = ({
  searchTerm,
  setSearchTerm,
  totalResultComposants,
  totalProductComponents,
  selectedProductComponents,
  handleDeleteSelected,
  handleAddSelectedToCart,
  handleCheckboxChange,
  totalSelectedComponents,
  totalSelectedComponentsQuantity,
}) => {

  const cartItems = useSelector((state) => state.cart.cartItems);
  const [itemsToAdd, setItemsToAdd] = useState(0);
  const [itemsToRemove, setItemsToRemove] = useState(0);

  useEffect(() => {
    const countItemsToAdd = (selectedProductComponents || []).filter(
      (itemId) => !cartItems.some((cartItem) => cartItem.id === itemId)
    ).length;
    const countItemsToRemove = (selectedProductComponents || []).filter(
      (itemId) => cartItems.some((cartItem) => cartItem.id === itemId)
    ).length;
    setItemsToAdd(countItemsToAdd);
    setItemsToRemove(countItemsToRemove);
  }, [cartItems, selectedProductComponents]);
  
  const headerTitles = [];
  const headerTags = {};

  if (totalSelectedComponents > 0) {
    var title = `${totalSelectedComponents} composant${totalSelectedComponents > 1 ? "s" : ""
      } sélectionné${totalSelectedComponents > 1 ? "s" : ""}`;
    headerTitles.push(title);
    headerTags["totalSelectedComponents"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  if (totalSelectedComponentsQuantity > 0) {
    var title = `${totalSelectedComponentsQuantity} pièce${totalSelectedComponentsQuantity > 1 ? "s" : ""
      } séléctionnée${totalSelectedComponentsQuantity > 1 ? "s" : ""}`;
    headerTitles.push(title);
    headerTags["totalSelectedComponentsQuantity"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }
  return (
    <div className="column is-full-mobile is-full-tablet is-half-desktop is-half-widescreen is-half-fullhd">
      <div className="block">
        <h4 className="subtitle is-pulled-left is-size-4 pr-3">Rechercher dans la liste des résultats :</h4>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Rechercher par référence ou nom de pièce"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="block">
        <div className="tags">
          {totalResultComposants !== totalProductComponents && (
            <span className="tag is-warning is-light is-outlined is-large">
              Résultats trouvés : {totalResultComposants}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;

ProductHeader.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  totalResultComposants: PropTypes.number.isRequired,
  totalProductComponents: PropTypes.number.isRequired,
  selectedProductComponents: PropTypes.array.isRequired,
  handleDeleteSelected: PropTypes.func.isRequired,
  handleAddSelectedToCart: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  totalSelectedComponents: PropTypes.number,
  totalSelectedComponentsQuantity: PropTypes.number,
};

