import React from "react";
import PropTypes from "prop-types";

const VCardLayout: React.FC = ({children}) => (
  <>
    {children}
  </>
);

VCardLayout.propTypes = {
  children: PropTypes.node,
};

export default VCardLayout;