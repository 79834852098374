import React from "react";
import "./style.scss";
import imageFondateurs from "./../../../assets/les_trois_fondateurs.png";

export default function AboutUs() {
  return (
    <div className="container is-fullhd">
      <section
        className="section is-medium auryris-padding"
        id="qui-sommes-nous"
      >
        <div className="content is-align-items-center">
          <h2 title="Qui sommes nous ?" className="title has-text-link">
            Qui sommes nous ?
          </h2>
          <div className="content ausyris-container">
            <p className="subtitle is-size-6-mobile is-size-6-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd is-size-5-fullhd hast-text-dark has-text-centered pb-6">
              AUSYRIS c&apos;est l&apos;histoire de trois anciens collègues de
              l&apos;industrie aéronautique qui se sont unis avec la volonté de
              relever de nouveaux challenges en tirant profit de leurs
              compétences et expériences complémentaires.
            </p>
          </div>
          <div className="columns pt-3">
            <div className="column is-two-fifths has-background-primary">
              <figure clasName="image is-1by3">
                <img src={imageFondateurs} alt="" />
              </figure>
            </div>
            <div className="column has-background-light">
              <h2
                title="Les trois fondateurs"
                className="title has-text-grey-light pt-6"
              >
                Les trois fondateurs
              </h2>
              <div className="content has-text-justified px-6 py-3">
                <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                  &quot;Il n&apos;y a pas de problèmes, que des solutions
                  &quot;. Sur ce mantra, nous avons fondé AUSYRIS pour proposer
                  des machines performantes sur des marchés exigeants.
                </p>
                <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                  Avec nos compétences complémentaires et un souci de qualité
                  opérationnelle et perçue, nous concevons des machines au
                  service de l&apos;utilisateur final, pour son confort, sa
                  sécurité et la performance de votre production.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
