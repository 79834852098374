import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import VCardLayout from "./../layouts/VCadLayout.tsx";

import Gbd from "./../components/Profiles/Gbd";
import Jpx from "./../components/Profiles/Jpx";
import Fct from "./../components/Profiles/Fct";


class About extends React.Component {
  render() {
    return (
      <VCardLayout>
        <Routes>
          <Route path="/gbd" element={<Gbd />} exact />
          <Route path="/jpx" element={<Jpx />} exact/>
          <Route path="/fct" element={<Fct />} exact/>
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </VCardLayout>
    );
  }
}
export default About;
