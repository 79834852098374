import React from "react";
import ContactForm from "./Form";

export default function ContactUs() {
  return (
    <div className="has-background-info">
    <div className="container is-fullhd has-background-info">
        <section className="section is-medium" id="nous-contacter">
        <div className="content is-align-items-center">
          <h2
            title="Contacter l&apos;équipe AUSYRIS"
            className="title has-text-primary"
          >
            Contacter l&apos;équipe AUSYRIS
          </h2>
          <div className="content">
            <p className="subtitle is-size-6 hast-text-ligth has-text-centered">
              Pour vos projets de machine spéciale ou de banc d&apos;essais, nous
              sommes disponibles pour étudier vos demandes techniques et
              répondre à vos questions !
            </p>
          </div>
        </div>
        <ContactForm />
      </section>
    </div>
    </div>
  );
}
