import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { PRODUCT_API_URL } from "../config.js";

const initialState = {
  id: undefined,
  yearOfCreation: undefined,
  clientName: undefined,
  productName: undefined,
  businessReference: undefined,
  productComponents: undefined,
  setSelectedProductComponents: [],
  itemsToAdd: undefined,
  itemsToRemove: undefined,
};

export const calculateItemsToAddRemove = () => (dispatch, getState) => {
  const state = getState();
  const selectedProductComponents = state.product.selectedProductComponents;
  const cartItems = state.cart.cartItems;

  const countItemsToAdd = (selectedProductComponents || []).filter(
    (itemId) => !cartItems.some((cartItem) => cartItem.id === itemId)
  ).length;
  const countItemsToRemove = (selectedProductComponents || []).filter(
    (itemId) => cartItems.some((cartItem) => cartItem.id === itemId)
  ).length;

  dispatch(setItemsToAdd(countItemsToAdd));
  dispatch(setItemsToRemove(countItemsToRemove));
};

export const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    addProductComponents: (state, action) => {
      state.productComponents = action.payload;
    },
    addId: (state, action) => {
      state.id = action.payload;
    },
    addYearOfCreation: (state, action) => {
      state.yearOfCreation = action.payload;
    },
    addClientName: (state, action) => {
      state.clientName = action.payload;
    },
    addProductName: (state, action) => {
      state.productName = action.payload;
    },
    addBusinessReference: (state, action) => {
      state.businessReference = action.payload;
    },
    setSelectedProductComponents: (state, action) => {
      state.selectedProductComponents = action.payload;
    },
    toggleProductComponentSelection: (state, action) => {
      const productId = action.payload;
      const isSelected = state.selectedProductComponents.includes(productId);

      if (isSelected) {
        state.selectedProductComponents = state.selectedProductComponents.filter(
          (id) => id !== productId
        );
      } else {
        state.selectedProductComponents.push(productId);
      }
    },
    setItemsToAdd: (state, action) => {
      state.itemsToAdd = action.payload;
    },
    setItemsToRemove: (state, action) => {
      state.itemsToRemove = action.payload;
    },
  },
  extraReducers: {
    ["cart/createCartItem"]: (state, action) => {
      state.productComponents.find(el => el.component.id === action.payload.id).picked = true;
      calculateItemsToAddRemove();
    },
    ["cart/deleteFromCart"]: (state, action) => {
      state.productComponents.find(el => el.component.id === action.payload).picked = false;
      calculateItemsToAddRemove();
    },
    ["cart/removeFromCartItem"]: (state, action) => {
      state.productComponents.find((el) => el.component.id === action.payload.id).picked = false;
      calculateItemsToAddRemove();
    },
  }
});

export function getProductByProductId(productId) {
  return async function (dispatch, getState) {
    const handleProductData = (data) => {
      dispatch(addId(data.id));
      dispatch(addYearOfCreation(data.year_of_creation));
      dispatch(addClientName(data.client_name));
      dispatch(addProductName(data.product_name));
      dispatch(addBusinessReference(data.business_reference));
      dispatch(addProductComponents(data.products_components));
    };

    try {
      const response = await fetch(
        `${PRODUCT_API_URL}/${productId}`
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      handleProductData(data);
    } catch (error) {
      console.error("Error fetching product data from API:", error);

      try {
        const fileResponse = await fetch("/data/data_of_product.json");
        if (!fileResponse.ok) {
          throw new Error(
            `JSON file request failed with status ${fileResponse.status}`
          );
        }

        const fileData = await fileResponse.json();
        handleProductData(fileData);
      } catch (fileError) {
        console.error("Error fetching product data from JSON file:", fileError);
      }
    }
  };
}

export const { addProductComponents,
  addId,
  addYearOfCreation,
  addClientName,
  addProductName,
  addBusinessReference,
  setSelectedProductComponents,
  toggleProductComponentSelection,
  setItemsToAdd,
  setItemsToRemove,
} = product.actions;

export default product.reducer;