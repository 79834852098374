import { configureStore } from "@reduxjs/toolkit";
// import products from "./features/products";
import product from "./features/product";
import cart from "./features/cart";

export const store = configureStore({
  reducer: {
    // products,
    product,
    cart,
  },
});