import React from "react";
import "./style.scss";

export default function IndustrialAutomationAndIT() {
  return (
    <div className="container is-fullhd">
      <section
        className="section is-small auryris-padding"
        id="automatisme-et-informatique"
      >
        <div className="columns">
          <div className="column is-half is-full-mobile">
            <section className="hero is-fullheight is-success banner-industrial-automation-and-it hero-industrial-automation-and-it">
              <div className="hero-body is-justify-content-center is-centered"></div>
            </section>
          </div>
          <div className="column">
            <section className="hero is-fullheight hero-industrial-automation-and-it-content">
              <div className="hero-body is-justify-content-center is-centered">
                <div className="content content-industrial-automation-and-it has-background-light">
                  <h2
                    className="title is-size-5-mobile is-size-4 has-text-grey-light has-text-left has-text-weight-bold is-family-sans-serif is-capitalized"
                    title="Automatisme & informatique industrielle"
                  >
                    Automatisme & <br />
                    informatique industrielle
                  </h2>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Intégrateur multi-marques, nous nous adaptons aux
                    spécificités de votre cahier des charges.
                  </p>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Les marques d&apos;automatismes sont sélectionnées pour
                    correspondre aux besoins de programmation de votre
                    application, mais aussi à son évolution future.
                  </p>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Environnement temps réel ? Programmation orientée objet ?
                    Interconnexion de MES pour remonter vos données de
                    production ?
                  </p>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Nous définissons des architectures distribuées au service de
                    systèmes intégrés de contrôle-commande, ainsi que des
                    interfaces Homme-Machine simples, modernes et ergonomiques
                    au service de vos utilisateurs.
                  </p>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif">
                    Pour des besoins de flexibilité ou d&apos;automatisation, le
                    choix de robot ou de cobot répondra à une étude menée sur
                    les volets économiques (ROI) et sur les aspects techniques :
                    précision, cadence, répétabilité et taux de service.
                  </p>

                  <p className="has-text-left has-text-weight-semibold is-family-sans-serif mt-5">
                    <div className="buttons">
                      <a
                        href="#nous-contacter"
                        className="button is-light is-outlined"
                      >
                        Contacter un expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
