import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: []
};

export const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    createCartItem: (state, action) => {
      state.cartItems.push(action.payload);
    },
    updateItemFromSelect: (state, action) => {
      state.cartItems.find((el) => el.id === action.payload.id).quantite =
        Number(action.payload.value);
    },
    removeFromCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    deleteFromCart: (state, action) => {
      const indexOfItemToRemove = state.cartItems.findIndex(
        (el) => el.id === action.payload
      );
      state.cartItems.splice(indexOfItemToRemove, 1);
    },
    plusOne: (state, action) => {
      const itemToUpdate = state.cartItems.find(
        (item) => item.id === action.payload
      );
      if (itemToUpdate) {
        itemToUpdate.quantite += 1;
      }
    },
    removeOne: (state, action) => {
      const itemToUpdate = state.cartItems.find(
        (item) => item.id === action.payload
      );
      if (itemToUpdate && itemToUpdate.quantite > 1) {
        itemToUpdate.quantite -= 1;
      }
    },
    emptyCart: (state) => {
      state.cartItems = [];
    },
  },
});

export function addOneToCart(action) {
  return function (dispatch, getState) {
    const storeState = getState();

    const isAlreadyPresent = storeState.cart.cartItems.find(el => el.id === action);

    if(!isAlreadyPresent){
      const itemToAdd = storeState.product.productComponents.find(
        (el) => el.component.id === action
      );

      const newCartItem = {
        id: itemToAdd.component.id,
        name: itemToAdd.component.name,
        label: itemToAdd.component.label,
        description: itemToAdd.component.description,
        designation_repere: itemToAdd.component.landmark,
        fabricant: itemToAdd.component.maker,
        reference_fabricant: itemToAdd.component.maker_reference,
        fournisseur: itemToAdd.component.supplier,
        reference_fournisseur: itemToAdd.component.supplier_reference,
        quantite: itemToAdd.component.quantity,
        picked: true,
        category: itemToAdd.component.category,
        quantityMin: itemToAdd.quantity_min,
        quantityMax: itemToAdd.quantity_max,
      };

      dispatch(createCartItem(newCartItem));
    }
  };
}

export const { createCartItem, removeFromCartItem, updateItemFromSelect, deleteFromCart,
  plusOne,
  removeOne,
  emptyCart, } = cart.actions;
export default cart.reducer;