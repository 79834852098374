import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductByProductId } from "../../features/product";

export default function Nav() {

  return (
    <>
    Footer Shop
    </>
  );
}
