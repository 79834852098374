import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import VCard from "./components/VCard";
import Pages from "./components/Pages";
import Shop from "./components/Shop";
import ContactMap from "./components/Contact/ContactMap";

import { store } from "./store.js";
import { Provider } from "react-redux";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Pages />} exact />
          <Route path="*" element={<Pages />} exact />
          <Route path="/contact" element={<ContactMap />} exact />
          <Route path="/contacts" element={<ContactMap />} exact />

          <Route path="/gbd" element={<Navigate to="/contact/gbd" />} exact />
          <Route path="/jpx" element={<Navigate to="/contact/jpx" />} exact />
          <Route path="/fct" element={<Navigate to="/contact/fct" />} exact />

          <Route path="/contact/*" element={<VCard />} />
          <Route path="/contacts/*" element={<VCard />} />

          <Route path="/e-shop/*" element={<Shop />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);