import React from "react";
import ProductDemo from "../ProductsList/ProductDemo";
import "../Navigation/style.scss";

export default function Basket() {
  return (
    <>
      <div className="container is-fullhd">
        <header className="section" id="basket">
          <h1 className="title">Démo</h1>
        </header>
      </div>
      <ProductDemo />
    </>
  );
}
