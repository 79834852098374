import React from "react";
import "./style.scss";

export default function DiscussWithUs() {
  return (
    <div className="container is-fullhd" id="echanger-avec-nous">
      <section className="section is-medium">
        <div className="columns">
          <div className="column is-half">
            <section className="hero is-fullheight is-success banner-discuss-with-us hero-discuss-with-us">
              <div className="hero-body is-justify-content-center is-centered"></div>
            </section>
          </div>
          <div className="column">
            <section className="hero is-fullheight hero-discuss-with-us-content">
              <div className="hero-body is-justify-content-center is-centered">
                <div className="content has-background-primary content-discuss-with-us">
                  <h2
                    className="title is-size-5-mobile is-size-4 has-text-primary has-text-left has-text-weight-bold is-family-sans-serif"
                    title="Échanger avec l'Équipe"
                  >
                    Échanger avec l&apos;équipe
                  </h2>
                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-justified is-family-sans-serif mb-5">
                    Notre flexibilité est notre force. Nous collaborons avec des
                    partenaires spécialisés pour créer des équipements{" "}
                    <abbr title="Banc de tests et/ou machines spéciales">
                      industriels
                    </abbr>{" "}
                    performants, repoussant les limites de l&apos;innovation.
                    Vous disposez d&apos;un maître d&apos;œuvre se positionnant
                    comme interlocuteur unique. Notre équipe vous écoute, étudie
                    vos process, s&apos;adapte à vos contextes spécifiques et
                    aux attentes de vos équipes.
                  </p>

                  <p className="is-size-7-mobile is-size-7-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd has-text-left has-text-weight-semibold is-family-sans-serif">
                    <div className="buttons">
                      <a
                        href="#nous-contacter"
                        className="button is-primary is-outlined"
                      >
                        Je veux parler de mon projet
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}
