import React from "react";
import PropTypes from "prop-types";
import TagsTemplate from "../Templates/TagsTemplate";

const ProductFooter = ({ totalProductComponents, totalQuantity, totalResultComposants, totalSelectedComponents, totalSelectedComponentsQuantity }) => {

  const handleTagClick = (tag) => {
    console.log("Clicked tag : " + tag);
  };

  const footerTags = {};

  if (totalProductComponents > 0) {
    var title = `Composants installés : ${totalProductComponents}`;
    footerTags["totalProductComponents"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  if (totalQuantity > 0) {
    var title = `Pièces installées : ${totalQuantity}`;
    footerTags["totalQuantity"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  if (totalResultComposants > 0) {
    var title = `Résultats trouvés : ${totalResultComposants}`;
    footerTags["totalResultComposants"] = {
      title: title,
      className: "tag is-warning is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  const myFooterTags = {};

  if (totalSelectedComponents > 0) {
    var title = `${totalSelectedComponents} composant${totalSelectedComponents > 1 ? "s" : ""
      } sélectionné${totalSelectedComponents > 1 ? "s" : ""}`;
    myFooterTags["totalSelectedComponents"] = {
      title: title,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }

  if (totalSelectedComponentsQuantity > 0) {
    myFooterTags["totalSelectedComponentsQuantity"] = {
      title: `${totalSelectedComponentsQuantity} pièce${totalSelectedComponentsQuantity > 1 ? "s" : ""
        } séléctionnée${totalSelectedComponentsQuantity > 1 ? "s" : ""}`,
      className: "tag is-white is-light is-large",
      onTagClick: () => {
        handleTagClick(title);
      },
    };
  }
  return (
    <div className="column is-full">
      <TagsTemplate tags={footerTags} />
      <TagsTemplate tags={myFooterTags} />
    </div>
  );
};

export default ProductFooter;

ProductFooter.propTypes = {
  totalProductComponents: PropTypes.number.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  totalResultComposants: PropTypes.number.isRequired,
  totalSelectedComponents: PropTypes.number.isRequired,
  totalSelectedComponentsQuantity: PropTypes.number.isRequired,
};
