import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOneToCart, removeFromCartItem, plusOne, removeOne } from "../../features/cart";
import PropTypes from "prop-types";


export default function ItemActions({item}) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);

  const isItemInCart = (itemId) => {
    return cartItems.some((cartItem) => cartItem.id === itemId);
  };

  return (
    <div className="buttons align-items align-content">
      {isItemInCart(item.id) ? (
        <>
          <button
            className="button is-danger is-light is-small is-fullwidth"
            onClick={() => dispatch(removeFromCartItem(item))}
          >
            <span title="Supprimer du panier">Supprimer</span>
          </button>
          <button
            title="Supprimer un du panier" className="button is-small" onClick={() => dispatch(removeOne(item.id))} disabled={cartItems.find(cartItem => cartItem.id === item.id)?.quantite <= 1}>
            -
          </button>
          <button disabled className="button is-success is-small">{cartItems.find(cartItem => cartItem.id === item.id)?.quantite || 0}</button>
          <button
            title="Ajouter un dans le panier"
            className="button is-small"
            onClick={() => dispatch(plusOne(item.id))}
            disabled={cartItems.find((cartItem) => cartItem.id === item.id)?.quantite >= item.quantityMax}
          >
            +
          </button>
        </>
      ) : (
        <button
          className="button is-primary is-small is-fullwidth"
          onClick={() => dispatch(addOneToCart(item.id))}
        >
            <span title="Ajouter au Panier">Ajouter</span>
        </button>
      )}
    </div>
  );
}

ItemActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantityMax: PropTypes.number.isRequired,
  }).isRequired,
};