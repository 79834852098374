
import React from "react";
import "./style.scss";

export default function Ausyris() {
  return (
    <div className="content ausyris-container is-align-items-center">
      <section className="section ausyris-section">
        <div className="content is-align-items-center">
          <div className="columns is-align-items-center">
            <div className="column has-background-primary">
              <div className="content is-align-items-center px-5 py-5">
                <div className="block">
                  <h1
                    className="title is-size-5-mobile is-size-4 has-text-primary"
                    title="Conception et réalisation de machines spéciales et moyens d&apos;essais sur-mesure pour les acteurs industriels exigeants"
                  >
                    Conception et réalisation de machines spéciales et moyens d&apos;essais sur-mesure pour les acteurs industriels exigeants
                  </h1>
                </div>
                <div className="block">
                  <p className="subtitle is-size-6-mobile is-size-6-tablet is-size-6-desktop is-size-6-widescreen is-size-5-fullhd is-size-5-fullhd has-text-white">
                    Grâce à notre panel d&apos;expertises complémentaires, nous traitons vos projets de l&apos;avant projet (AVP) à la mise en service et au déploiement sur site.
                  </p>
                </div>
                <div className="block my-3">
                  <a
                    href="#nous-contacter"
                    className="button is-primary is-outlined button-left-bar my-3"
                  >
                    Contacter un expert
                  </a>
                  <a
                    href="#notre-expertise"
                    className="button is-primary is-outlined ml-5 button-right-bar my-3"
                  >
                    Notre expertise
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
