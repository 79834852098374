import "bulma/css/bulma.css";
// import "./ShopLayout.scss";

import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import imageLogo from "./../assets/image002.png";
import imageLogoBlanc from "./../assets/AUSYRIS.png";

import ShopNavigation from "./../components/Navigation/ShopNavigation";
import ShopFooter from "./../components/Footer/ShopFooter";


function ShopLayout({children}) {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    const $navbar = document.querySelector(".navbar");
    const $navbarBrand = document.querySelector(".navbar-brand");
    const $navbarMenu = document.querySelector(".navbar-menu");

    if (scrollTop >= 500) {
      $navbar.classList.add("sticky");
      $navbarMenu.classList.add("sticky");
      $navbarBrand.classList.add("sticky");
      document.getElementById("ausyris-logo").src = imageLogoBlanc;
    } else {
      $navbar.classList.remove("sticky");
      $navbarMenu.classList.remove("sticky");
      $navbarBrand.classList.remove("sticky");
      document.getElementById("ausyris-logo").src = imageLogo;
    }
  });

  return (
    <div className="App" id="AUSYRIS">
      <section className="hero is-primary is-halfheight is-from-top banner-home">
        <ShopNavigation />
        <div className="hero-body"></div>
      </section>
      <div className="body-content">
        {children}
      </div>
      <ShopFooter />
    </div>
  );
}

ShopLayout.propTypes = {
  children: PropTypes.node,
};

export default ShopLayout;
