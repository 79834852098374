import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import imageLogo from "./../../assets/image002.png";
import "./style.scss";
import FloatingCartButton from "../../components/FloatingCartButton";

export default function Nav() {
  useEffect(() => {
    try {
      const $navbarBurger = document.querySelector(".navbar-burger");
      const $navbarMenu = document.querySelector(".navbar-menu");
      const $navbarItems = document.querySelectorAll(".navbar-item");

      $navbarBurger.addEventListener("click", (event) => {
        $navbarBurger.classList.toggle("is-active");
        $navbarMenu.classList.toggle("is-active");
      });
      $navbarItems.forEach((el) => {
        el.addEventListener("click", (event) => {
          $navbarBurger.classList.toggle("is-active");
          $navbarMenu.classList.toggle("is-active");
        });
      });
    } catch (e) {
      console.warn(e);
    }
  }, []);

  return (
    <>
      <nav
        className="navbar is-fixed-top is-transparent"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item is-primary">
            <img
              src={imageLogo}
              alt="AUSYRIS"
              id="ausyris-logo"
              className="nav-brand-img"
            />
          </Link>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start"></div>

          <div className="navbar-end is-expanded is-tab">
            <div className="navbar-item">
              <div className="buttons">
                <FloatingCartButton />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
