import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { emptyCart } from "../features/cart";
import ItemActions from "./ProductsList/ItemActions";

export default function Cart() {
  const cart = useSelector((state) => state.cart);  
  const dispatch = useDispatch();

  const sendCartToAPI = async () => {
    try {
      const cartData = cart.cartItems.map((item) => ({
        product_id: item.id,
        quantity: item.quantite,
      }));

      const response = await fetch("/your-api-endpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: cartData }),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      console.log("Cart data sent successfully:", cartData);

    } catch (error) {
      console.error("Error sending cart to API:", error);
    }
  };


  return (
    <div>
      <h2 className="is-size-2 is-grey mb-3">Votre panier</h2>
      <div className="table-container" id="cart-items">
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <tbody>
            {cart.cartItems.length > 0 ? (
              cart.cartItems.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.description}</span>
                  </td>
                  <td>
                    <ItemActions item={item} />
                  </td>
                </tr>
              ))
            ) : (
              <p>Votre panier est vide.</p>
            )}
          </tbody>
        </table>
      </div>

      <div className="block" id="cart-total-price">
        Total de pièces :{" "}
        {cart.cartItems.reduce((acc, curr) => acc + curr.quantite, 0)}
      </div>
      <div className="block" id="cart-buttons">
        <div className="buttons">
          <button
            className="button is-success"
            id="cart-checkout"
            onClick={sendCartToAPI}
          >
            Valider votre commande
          </button>
          <button
            className="button is-light"
            onClick={() => setShowModal(false)}
          >
            Continuer vos achats
          </button>
          <button className="button is-danger" onClick={() => dispatch(emptyCart())}>
            Vider le panier
          </button>
          
        </div>
      </div>
    </div>
  );
}
